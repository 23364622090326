@import '~bootstrap/dist/css/bootstrap.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css';

body {
    background: transparent linear-gradient(180deg, #8CD3E7 0%, #89AAE8 100%) 0% 0% no-repeat padding-box;
    text-align:center;
    margin:0;     /* This is used to reset any browser-default margins */
    height:100vh; /* This is needed to overcome a Chrome bug. */
    width:100vw;
}

.content {
    align-items: center;
    -webkit-box-align: center;
    text-align: center;
    display: flex;
    height:100vh;
}

.row {
    margin-left:0px !important;
    margin-right:0px !important;
}

.error-title {
    font: Bold 40px/26px Arial;
    letter-spacing: 0;
    color: #FFFFFF;
    padding-bottom:20px;
}

.error-subtitle {
    font: 22px/26px Arial;
    letter-spacing: 0;
    color: #FFFFFF;
    padding-bottom:20px;
}

.error-message{
    font: 13px/18px Arial;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
}

.error-rhlogo {
    padding-top:35%;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .error-rhlogo {
        padding-top:70%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .error-rhlogo {
        padding-top:35%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .error-rhlogo {
        padding-top:30%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .error-rhlogo {
        padding-top:20%;
    }
}